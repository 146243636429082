<template>
	<div style="width: 100%">
		<v-text-field
				v-if="type === 'integer' || type === 'float'"
				v-model.number="new_val"
				outlined
				dense
				hide-details
				class="rounded-lg paragraph"
				:type="type"
				:height="30"
				:disabled="readonly"
				:background-color="backgroundColor"
				:placeholder="placeholder"
				:style="styles"
				:autofocus="autofocus"
				@keyup="(e) => $emit('keyup', e)"
				@keyup.enter="(e) => $emit('enter', e)"
		>
			<template v-slot:append>
				<v-icon v-if="clearable && new_val !== '' && new_val !== null && typeof new_val !== 'undefined'" size="18" @click="new_val = null; $emit('clear')" style="position: relative; bottom: 2px; right: -3px;">mdi-close</v-icon>
				<v-icon v-if="appendIcon" size="18" class="ml-2" @click="iconClick" style="position: relative; bottom: 2px;">{{ appendIcon }}</v-icon>
			</template>

			<template v-slot:label v-if="label">
				<div class="paragraph" style="margin-top: -4px;">{{ label | firstCapital }}</div>
			</template>
		</v-text-field>

		<v-text-field
				v-else
				v-model="new_val"
				outlined
				dense
				hide-details
				class="rounded-lg paragraph"
				:type="type"
				:height="30"
				:disabled="readonly"
				:background-color="backgroundColor"
				:placeholder="placeholder"
				:style="styles"
				:autofocus="autofocus"
				@keyup="(e) => $emit('keyup', e)"
				@keyup.enter="(e) => $emit('enter', e)"
		>
			<template v-slot:append>
				<v-icon v-if="clearable && new_val !== '' && new_val !== null && typeof new_val !== 'undefined'" size="18" @click="new_val = null; $emit('clear')" style="position: relative; bottom: 2px; right: -3px;">mdi-close</v-icon>
				<v-icon v-if="appendIcon" size="18" class="ml-2" @click="iconClick" style="position: relative; bottom: 2px;">{{ appendIcon }}</v-icon>
			</template>

			<template v-slot:label v-if="label">
				<div class="paragraph" style="margin-top: -4px;">{{ label | firstCapital }}</div>
			</template>
		</v-text-field>
	</div>
</template>

<script>
	export default {
		name: "TextField",
		props: {
			value: {},
			label: {type: String},
			backgroundColor: {type: String, default: 'cell'},
			type: {type: String},
			styles: {},
			appendIcon: {type: String},
			clearable: {type: Boolean},
			autofocus: {type: Boolean},
			readonly: {type: Boolean},
			iconClick: {type: Function, default: () => {}},
			placeholder: {}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
